/*
 * @Author: dongjia
 * @Date: 2021-08-17 11:41:39
 * @LastEditTime: 2021-08-17 11:44:11
 * @LastEditors: aleaner
 * @Description: 社区管理设置接口
 * @FilePath: \saas-admin-vue\src\modules\community-admin\api\community-config.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

// 获取设置配置
export const configDetail = data => {
  return api({
    url: "/admin/circle/config/getConfig",
    method: "post",
    data
  });
};

// 保存设置配置
export const saveConfig = data => {
  return api({
    url: "/admin/circle/config/saveConfig",
    method: "post",
    data
  });
};