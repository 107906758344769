<!--
 * @Author: dongjia
 * @Date: 2021-08-17 11:42:25
 * @LastEditTime: 2021-10-19 14:28:53
 * @LastEditors: aleaner
 * @Description: 社区管理设置页面
 * @FilePath: \saas-admin-vue\src\modules\community-admin\views\community-config.vue
 * 怎么可能会有bug！！！
-->
<!--
 * @Author: dongjia
 * @Date: 2021-08-16 17:00:25
 * @LastEditTime: 2021-08-16 18:35:51
 * @LastEditors: aleaner
 * @Description: 新增帖子页面
 * @FilePath: \saas-admin-vue\src\modules\community-admin\views\post-admin\add-post.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="group-content" v-loading="formLoading">
    <el-form size="medium" :model="configForm.check" label-position="right" label-width="85px"
      class="small-form" @submit.native.prevent>
      <div class="modular">
        <p class="modular-title">审核设置</p>
        <div class="modular-content" style="margin-left: 11px;">
          <el-form-item label="发帖审核：" prop="post">
            <el-radio v-model="configForm.check.post" :label="1">先发后审</el-radio>
            <el-radio v-model="configForm.check.post" :label="0">先审后发</el-radio>
          </el-form-item>
          <el-form-item label="评论审核：" prop="comment">
            <el-radio v-model="configForm.check.comment" :label="1">先发后审</el-radio>
            <el-radio v-model="configForm.check.comment" :label="0">先审后发</el-radio>
          </el-form-item>
          <el-form-item label="话题审核：" prop="topic">
            <el-radio v-model="configForm.check.topic" :label="0">无需审核</el-radio>
            <el-radio v-model="configForm.check.topic" :label="1">前端创建需审核</el-radio>
            <el-radio v-model="configForm.check.topic" :label="2">前后端都需要审核</el-radio>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <el-form size="medium" :model="configForm.blacklist" label-position="right" label-width="85px"
      class="small-form" @submit.native.prevent>
      <div class="modular">
        <p class="modular-title">黑名单设置</p>
        <div class="modular-content" style="margin-left: 11px;">
          <el-form-item label="黑名单限制：" prop="config">
            <el-checkbox-group v-model="configForm.blacklist.config">
              <el-checkbox :label="1">无法发帖</el-checkbox>
              <el-checkbox :label="2">无法评论</el-checkbox>
              <el-checkbox :label="3">无法申请加入</el-checkbox>
            </el-checkbox-group>
            <div class="form-tip" style="margin-top: 5px">
              <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
              <span>当前设置针对小组全局，如用户为小组黑名单，则无法申请加入任何小组或发布评论等。</span>
            </div>
          </el-form-item>
          <el-form-item label-width="110px" label="黑名单自动移出：" prop="auto_move">
            <el-input v-model.number="configForm.blacklist.auto_move">
              <i slot="suffix" style="margin-right: 10px;">天</i>
            </el-input>
            <div class="form-tip">
              <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
              <span>设置用户被拉入黑名单后，自动移出黑名单的天数，为空，则不自动移出，需手动移出。</span>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <el-form size="medium" :model="configForm.admin_account" :rules="rules" label-position="right"
      label-width="85px" class="small-form" ref="configForm" @submit.native.prevent>
      <div class="modular">
        <p class="modular-title">官方账号设置</p>
        <div class="modular-content" style="margin-left: 11px;">
          <el-form-item label="账号名称：" prop="name">
            <el-input v-model="configForm.admin_account.name" placeholder="请输入官方账号名称"></el-input>
            <div class="form-tip">
              <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
              <span>后台创建帖子、发布帖子、回复评论等操作均用此账号进行前端显示</span>
            </div>
          </el-form-item>
          <el-form-item label="头像：" prop="avatar">
            <SingleMediaWall v-model="configForm.admin_account.avatar" addText="" :borderRadius="4"
              :width="96" :height="96" ratio="96:96">
              <div class="form-img" slot="info">建议尺寸为 96px*96px</div>
            </SingleMediaWall>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <el-form size="medium" :model="configForm.share" label-position="right" label-width="85px"
      class="small-form" @submit.native.prevent>
      <div class="modular">
        <p class="modular-title">分享配置</p>
        <div class="modular-content" style="margin-left: 11px;">
          <el-form-item label="分享标题：" prop="title">
            <el-input v-model="configForm.share.title" placeholder="请输入分享标题"></el-input>
          </el-form-item>
          <el-form-item label="分享图片：" prop="image">
            <SingleMediaWall v-model="configForm.share.image" addText="" :width="150" :height="96" ratio="5:4">
              <div class="form-img" slot="info">建议尺寸为 500px*400px</div>
            </SingleMediaWall>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <FixedActionBar>
      <el-button type="primary" size="medium" @click="formSubmit('configForm')" :loading="saveLoading">保存
      </el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import { configDetail, saveConfig } from "../api/community-config";
import SingleMediaWall from "../../common/components/SingleMediaWall.vue";
import FixedActionBar from "@/base/layout/FixedActionBar";
export default {
  components: {
    SingleMediaWall,
    FixedActionBar,
  },
  data() {
    return {
      formChanging: false,
      configForm: {
        check: {
          post: 0, //发帖审核，0：先发后审，1：先审后发
          comment: 0, //评论审核，0：先发后审，1：先审后发
          topic: 0, //话题审核，0：无需审核，1：前端审核，2：前后端需要审核
        },
        blacklist: {
          config: [],
          auto_move: "",
        },
        admin_account: {
          name: "",
          avatar: "",
        },
        share: {
          title: "",
          image: "",
        },
      },
      rules: {
        name: [{ required: true, message: "请输入账号名称", trigger: "blur" }],
      },
      formLoading: false,
      saveLoading: false,
    };
  },
  methods: {
    // 获取品牌详情
    getConfigDetail() {
      this.formLoading = true;
      configDetail({ id: this.configForm.id })
        .then((res) => {
          const { data } = res;
          this.configForm = data;
          this.formLoading = false;
        })
        .catch((err) => {
          this.formLoading = false;
        });
    },
    // 保存
    formSubmit(keyWords) {
      this.$refs[keyWords].validate((valid) => {
        if (valid) {
          const data = { ...this.configForm };
          this.saveLoading = true;
          saveConfig({ config: data })
            .then((res) => {
              this.$message.success(res.msg);
              this.getConfigDetail();
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({ block: "center", behavior: "smooth" });
          }, 100);
          this.$message.error("请填写完整信息");
          return false;
        }
      });
    },
  },
  created() {
    this.getConfigDetail();
  },
};
</script>

<style lang="scss" scoped>
.group-content {
  min-height: 520px;
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}

.form-img {
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 8px;
}
</style>
